import React from "react";
import { Routes, Route } from "react-router-dom";
import { DashboardToolbar} from "../../Components/components";
import ImagesSection from "./Images/Images";
import VideosSection from "./Videos/Videos";
import BookingsSection from "./Bookings/Bookings";
import "./DashboardPage.scss";
import DashboardHome from "./Home/DashboardHome";

const DashboardPage: React.FC = () => {


  return (
    <div className="dashboard-page">
      <DashboardToolbar />
      <main className="dashboard-content">
        <Routes>
          <Route path="home" element={<DashboardHome />}/>
          <Route path="images" element={<ImagesSection />} />
          <Route path="videos" element={<VideosSection />} />
          <Route path="bookings" element={<BookingsSection />} />
          <Route path="*" element={<p>Select a section from the toolbar.</p>} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardPage;
