import React from "react";
import "./Select.scss";
import { FaChevronDown } from "react-icons/fa";
import { motion } from "framer-motion";

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps {
  id?: string;
  name?: string;
  required?: boolean;
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  options: Option[];
  value?: string | number;
  title?: string;
  onChange: (value: string | number) => void;
  placeholder?: string;
  ariaLabel?: string;
  disabled?: boolean;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  id,
  name,
  required = false,
  theme = "primary",
  options,
  title,
  value,
  onChange,
  placeholder = "Select an option",
  ariaLabel,
  disabled = false,
  className,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div
      className={`select-main ${theme} ${className || ""} ${disabled ? "disabled" : ""}`}
      aria-live="polite"
    >
      <select
        id={id}
        name={name}
        required={required}
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel || placeholder}
        disabled={disabled}
        className={`select ${theme}`}
        tabIndex={-1}
        title={title}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <motion.div
        className={`select-icon ${theme} ${disabled ? "disabled" : ""}`}
        transition={{ duration: 0.3 }}
        aria-hidden="true"
        style={{ transformOrigin: "center" }}
      >
        <FaChevronDown />
      </motion.div>
    </div>
  );
};

export default Select;
