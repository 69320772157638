import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, ProgressBar } from "../components";
import "./YoutubeLinkUpload.scss";

interface VideoUploadProps {
  onUploadSuccess: () => void;
}

const VideoUpload: React.FC<VideoUploadProps> = ({ onUploadSuccess }) => {
  const [iframeCode, setIframeCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);

  const extractVideoData = (iframeCode: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeCode, "text/html");
    const iframe = doc.querySelector("iframe");

    if (!iframe) {
      throw new Error("Invalid iframe code. Please paste a valid YouTube embed code.");
    }

    const src = iframe.getAttribute("src");
    const title = iframe.getAttribute("title") || "Untitled Video";

    if (!src || !src.includes("youtube.com/embed")) {
      throw new Error("Invalid YouTube embed code. Please ensure it contains a valid YouTube URL.");
    }

    return { src, title };
  };

  const handleVideoUpload = async () => {
    setErrorMessage(""); 
    try {
      if (!iframeCode) {
        throw new Error("Please paste the iframe code.");
      }

      setIsUploading(true);

      const { src, title } = extractVideoData(iframeCode);

      await addDoc(collection(db, "galleryYoutubeVideos"), {
        videoUrl: src,
        videoName: title,
        createdAt: new Date(),
      });

      setIframeCode("");
      onUploadSuccess();
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : "An unknown error occurred.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="video-upload">
      <h2>Upload Video</h2>
      <div className="form-group">
        <textarea
          placeholder="Paste YouTube embed iframe code here"
          value={iframeCode}
          onChange={(e) => setIframeCode(e.target.value)}
          disabled={isUploading}
        />
      </div>
      <Button onClick={handleVideoUpload} theme="primary" disabled={isUploading}>
        {isUploading ? "Uploading..." : "Upload Video"}
      </Button>
      {isUploading && <ProgressBar progress={100} />}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default VideoUpload;
