import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { createVenueBookingContractPDF } from "../Utils/pdfGen";
import axios from "axios";
import { db } from "../firebase";

/**
 * Define the type for Booking Data based on the Firestore document structure
 */
export interface BookingData {
  id: string;
  contactEmail: string;
  date: string;
  description: string;
  name: string;
  status: string;
  time: string;
  deleteBy?: string;
  additionalServices: {
    soundTech: boolean;
    doorPerson: boolean;
  };
  socialLinks: {
    youtube: string;
    instagram: string;
    facebook: string;
    spotify: string;
  };
}

interface ApprovalDetails {
  id: string;
  name: string;
  contactEmail: string;
  description: string;
  date: string;
  time: string;
  additionalServices: {
    soundTech: boolean;
    doorPerson: boolean;
  };
  socialLinks: {
    youtube: string;
    instagram: string;
    facebook: string;
    spotify: string;
  };
}

/**
 * Fetch all bookings from Firestore.
 * @returns Promise<BookingData[]> - Array of booking objects
 */
export const fetchBookings = async (): Promise<BookingData[]> => {
  try {
    const bookingCollection = collection(db, "bookingRequests");
    const bookingSnapshot = await getDocs(bookingCollection);

    return bookingSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as Omit<BookingData, "id">), // Ensure proper typing
    }));
  } catch (error) {
    console.error("Error fetching bookings:", error);
    throw error;
  }
};

/**
 * Fetch all booking dates from Firestore for calendar display.
 * @returns Promise<string[]> - Array of booking date strings
 */
export const fetchBookingDates = async (): Promise<string[]> => {
    try {
      const bookingDatesCollection = collection(db, "bookingDates");
      const bookingDatesSnapshot = await getDocs(bookingDatesCollection);
  
      return bookingDatesSnapshot.docs.map((doc) => doc.data().date as string);
    } catch (error) {
      console.error("Error fetching booking dates:", error);
      throw error;
    }
  };

/**
 * Add a new booking request to Firestore.
 * @param booking - BookingData object (without the `id`)
 * @returns Promise<void>
 */
export const addBooking = async (booking: Omit<BookingData, "id">): Promise<void> => {
    try {
      // Add full booking details to "bookingRequests"
      await addDoc(collection(db, "bookingRequests"), booking);
  
      // Add just the date to "bookingDates"
      await addDoc(collection(db, "bookingDates"), { date: booking.date.split("T")[0] });
    } catch (error) {
      console.error("Error adding booking:", error);
      throw error;
    }
  };
/**
 * Format and handle the booking request submission.
 * @param name - Name of the requester
 * @param contactEmail - Contact email of the requester
 * @param description - Description of the booking
 * @param time - Booking time in HH:mm format
 * @param selectedDate - Selected date for the booking in ISO format
 * @param additionalServices -Requested additional services for the booking
 * @param socialLinks - Social links for the headliner band
 */
export const handleBookingRequest = async (
  name: string,
  contactEmail: string,
  description: string,
  time: string,
  selectedDate: string,
  additionalServices: { soundTech: boolean; doorPerson: boolean },
  cleanedLinks: { youtube: string; instagram: string; facebook: string; spotify: string }
): Promise<void> => {
  if (!selectedDate) {
    throw new Error("Selected date is required for booking.");
  }

  const fullDateTime = `${selectedDate}T${time}:00`;

  const newBooking: Omit<BookingData, "id"> = {
    name,
    contactEmail,
    description,
    time,
    date: fullDateTime,
    status: "pending",
    additionalServices,
    socialLinks: cleanedLinks,
  };

  // Save booking to the database
  await addBooking(newBooking);

  // Attempt to send confirmation email
  try {
    await axios.post(
      "https://sendbookingemail-imbnnmd5ea-uc.a.run.app",
      {
        name,
        email: contactEmail,
        description,
        date: selectedDate,
        time,
        adminEmail: "booking@dogpit.ca",
        additionalServices,
        socialLinks: cleanedLinks,
      }
    );
  } catch (error) {
    console.error("Error sending booking email:", error);
    throw new Error("Booking confirmation email could not be sent.");
  }
};

export const deleteBooking = async (bookingId: string, bookingDate: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, "bookingRequests", bookingId));

    const bookingDatesSnapshot = await getDocs(collection(db, "bookingDates"));
    const dateDoc = bookingDatesSnapshot.docs.find(
      (doc) => doc.data().date === bookingDate.split("T")[0]
    );

    if (dateDoc) {
      await deleteDoc(doc(db, "bookingDates", dateDoc.id));
    }
  } catch (error) {
    console.error("Error deleting booking:", error);
    throw new Error("Failed to delete booking.");
  }
};

/**
 * Update an existing booking in Firestore.
 * @param bookingId - The ID of the booking to update.
 * @param updatedFields - An object containing the fields to update.
 * @returns Promise<void>
 */
export const updateBooking = async (
  bookingId: string,
  updatedFields: Partial<Omit<BookingData, "id">>
): Promise<void> => {
  try {
    const bookingDocRef = doc(db, "bookingRequests", bookingId);

    // Update the specified fields in the booking document
    await updateDoc(bookingDocRef, updatedFields);

  } catch (error) {
    console.error("Error updating booking:", error);
    throw new Error("Failed to update booking.");
  }
};

/**
 * Close an existing booking by updating its status to "closed", adding a `deleteBy` date, and removing it from the `bookingDates` DB.
 * @param bookingId - The ID of the booking to close.
 * @param bookingDate - The date of the booking to remove from the `bookingDates` DB.
 * @returns Promise<void>
 */
export const closeBooking = async (bookingId: string, bookingDate: string): Promise<void> => {
  try {
    const bookingDocRef = doc(db, "bookingRequests", bookingId);

    // Calculate the `deleteBy` date (e.g., 30 days from now)
    const deleteByDate = new Date();
    deleteByDate.setDate(deleteByDate.getDate() + 30);

    // Update the booking status to "closed" and add `deleteBy` date
    await updateDoc(bookingDocRef, {
      status: "completed",
      closedDate: new Date().toISOString(), // Record the date when it was closed
      deleteBy: deleteByDate.toISOString(), // Schedule for deletion in 30 days
    });

    // Remove the date from the `bookingDates` collection
    const bookingDatesSnapshot = await getDocs(collection(db, "bookingDates"));
    const dateDoc = bookingDatesSnapshot.docs.find(
      (doc) => doc.data().date === bookingDate.split("T")[0] // Compare dates in YYYY-MM-DD format
    );

    if (dateDoc) {
      await deleteDoc(doc(db, "bookingDates", dateDoc.id));
    } else {
      console.warn(`Booking date ${bookingDate.split("T")[0]} not found in bookingDates.`);
    }
  } catch (error) {
    console.error("Error closing booking:", error);
    throw new Error("Failed to close booking.");
  }
};

/**
 * Approve a booking by generating the contract PDF, updating its status, and sending the approval email.
 * @param approvalDetails - Details of the booking to approve.
 * @param contractDetails - Details for generating the contract PDF.
 * @returns Promise<void>
 */
export const approveBooking = async (
  approvalDetails: ApprovalDetails,
  contractDetails: any
): Promise<void> => {
  const {
    id,
    name,
    contactEmail,
    description,
    date,
    time,
    additionalServices,
    socialLinks,
  } = approvalDetails;

  try {
    // Generate the contract PDF
    const contractBase64 = await createVenueBookingContractPDF(contractDetails);

    // Update the booking's status in Firestore
    await updateBooking(id, { status: "approved" });

    // Send the approval email with the generated PDF
    const emailResponse = await fetch("https://sendapprovalemail-imbnnmd5ea-uc.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email: contactEmail,
        description,
        date,
        time,
        additionalServices,
        socialLinks,
        contractBase64,
      }),
    });

    if (!emailResponse.ok) {
      throw new Error("Failed to send approval email.");
    }
  } catch (error) {
    console.error("Error approving booking:", error);
    throw error;
  }
};
