import React, { useState, useEffect } from "react";
import { Button, Pager, Spinner, ContractModal, ViewBookingModal, Alert } from "../../../Components/components";
import { fetchBookings, BookingData, deleteBooking, updateBooking, approveBooking } from "../../../APIs/BookingsAPI";
import { fetchAllEvents, EventData } from "../../../APIs/EventsAPI";
import { createVenueBookingContractPDF } from "../../../Utils/pdfGen";
import "./Bookings.scss";
import { formatDate } from "../../../Utils/formatter";

const ITEMS_PER_PAGE = 5;

const emptyBookingData: BookingData = {
  id: "",
  contactEmail: "",
  date: "",
  description: "",
  name: "",
  status: "pending",
  time: "",
  additionalServices: {
    soundTech: false,
    doorPerson: false,
  },
  socialLinks: {
    facebook: "",
    instagram: "",
    youtube: "",
    spotify: "",
  }
};

const BookingsSection: React.FC = () => {
  const [bookings, setBookings] = useState<BookingData[]>([]);
  const [events, setEvents] = useState<EventData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [, setCurrentBookingPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alert, setAlert] = useState<{ type: "success" | "error"; message: string } | null>(null);
  const [currentEventPage, setCurrentEventPage] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<BookingData>(emptyBookingData);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewBookingDetails, setViewBookingDetails] = useState<BookingData | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [bookingData, eventbriteData] = await Promise.all([
          fetchBookings(),
          fetchAllEvents(),
        ]);

        const sortedBookings = bookingData.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        const sortedEvents = eventbriteData.events.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        setBookings(sortedBookings);
        setEvents(sortedEvents);
      } catch (err) {
        console.error("Error loading data:", err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handlePageChange = (type: "booking" | "event", page: number) => {
    if (type === "booking") setCurrentBookingPage(page);
    else if (type === "event") setCurrentEventPage(page);
  };

  const refreshBookings = async () => {
    try {
      const bookingData = await fetchBookings();
      setBookings(
        bookingData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      );
    } catch (err) {
      console.error("Error refreshing bookings:", err);
      setAlert({ type: "error", message: "Failed to refresh bookings." });
    }
  };

  const handleApprove = (booking: BookingData) => {
    setSelectedBooking(booking);
    setModalOpen(true);
  };

  const submitApproval = async (contractDetails: any) => {
    if (!selectedBooking) return;
  
    try {
      await approveBooking(selectedBooking, contractDetails);
      setBookings((prevBookings) =>
        prevBookings.map((b) =>
          b.id === selectedBooking.id ? { ...b, status: "approved" } : b
        )
      );
  
      setAlert({ type: "success", message: "Booking approved and requester notified." });
    } catch (error) {
      console.error("Error approving booking:", error);
      setAlert({ type: "error", message: "Failed to approve booking. Please try again." });
    } finally {
      setModalOpen(false);
      setSelectedBooking(emptyBookingData);
    }
  };

  async function handleReject(bookingId: string) {
    const booking = bookings.find((b) => b.id === bookingId);

    if (!booking) {
      setAlert({ type: "error", message: "Booking not found." });
      return;
    }

    const rejectionReason = prompt("Enter the reason for rejection:");

    if (!rejectionReason) {
      setAlert({ type: "error", message: "Rejection reason is required." });
      return;
    }

    try {
      // Delete the booking from Firestore
      await deleteBooking(bookingId, booking.date);

      // Send rejection email
      const response = await fetch("https://sendrejectionemail-imbnnmd5ea-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: booking.name,
          email: booking.contactEmail,
          date: booking.date,
          time: booking.time,
          reason: rejectionReason,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send rejection email.");
      }

      // Update UI
      setBookings((prevBookings) => prevBookings.filter((b) => b.id !== bookingId));

      setAlert({ type: "success", message: "Booking rejected and requester notified." });
    } catch (error) {
      console.error("Error rejecting booking:", error);
      setAlert({ type: "error", message: "Failed to reject booking. Please try again." })
    }
  }

  if (loading) {
    return (
      <div className="loading-page">
        <Spinner size={100} />
        <p>Loading data...</p>
      </div>
    );
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  const pendingBookings = bookings.filter((b) => b.status === "pending");
  const approvedBookings = bookings.filter((b) => b.status === "approved" || b.status === "completed");

  const paginatedEvents = events.slice(
    (currentEventPage - 1) * ITEMS_PER_PAGE,
    currentEventPage * ITEMS_PER_PAGE
  );

  const paginatedPending = pendingBookings.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const paginatedApproved = approvedBookings.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleViewBooking = (booking: BookingData) => {
    setViewBookingDetails(booking);
    setViewModalOpen(true);
  };

  return (
    <div className="bookings-section">
      {alert && <Alert type={alert.type} onClose={() => setAlert(null)} message={alert.message}/>}
      <header className="section-header">
        <h2>Manage Bookings</h2>
        <p>Review Booking Requests and Upcoming Events.</p>
      </header>

      <div className="section pending-bookings">
        <h3>Pending Bookings</h3>
        {pendingBookings.length === 0 ? (
          <p>No pending bookings found.</p>
        ) : (
          <>
            <ul>
              {paginatedPending.map((booking) => (
                <li key={booking.id}>
                  <div className="booking-info">
                    <p>Event Host: {booking.name}</p>
                    <p>Contact Email: {booking.contactEmail}</p>
                    <p>Description: {booking.description}</p>
                    <p>Date: {formatDate(booking.date)}</p>
                    {booking.socialLinks.facebook && (
                      <a href={booking.socialLinks.facebook} target="_blank" rel="noreferrer">
                        Facebook
                      </a>
                    )}
                    <br />
                    {booking.socialLinks.instagram && (
                      <a href={booking.socialLinks.instagram} target="_blank" rel="noreferrer">
                        Instagram
                      </a>
                    )}
                    <br />
                    {booking.socialLinks.spotify && (
                      <a href={booking.socialLinks.spotify} target="_blank" rel="noreferrer">
                        Spotify
                      </a>
                    )}
                    <br />
                    {booking.socialLinks.youtube && (
                      <a href={booking.socialLinks.youtube} target="_blank" rel="noreferrer">
                        YouTube
                      </a>
                    )}
                    <p>Status: {booking.status}</p>
                  </div>
                  <Button onClick={() => handleApprove(booking)} theme="success">
                    Approve
                  </Button>
                  <Button onClick={() => handleReject(booking.id)} theme="error">
                    Reject
                  </Button>
                </li>
              ))}
            </ul>
            <Pager
              totalItems={pendingBookings.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>

      <div className="section approved-bookings">
        <h3>Approved Bookings</h3>
        {approvedBookings.length === 0 ? (
          <p>No approved bookings found.</p>
        ) : (
          <>
            <ul>
              {paginatedApproved.map((booking) => (
                <li key={booking.id}>
                  <div className="booking-info">
                    <strong>Event host</strong>
                    <p>{booking.name}</p>
                    <strong>Date</strong>
                    <p>{formatDate(booking.date)}</p>
                    <strong>Status</strong>
                    <p>{booking.status}</p>
                    {booking.deleteBy && 
                    <>
                    <strong>Deletion Date</strong>
                    <p>{formatDate(booking.deleteBy)}</p>
                    </>
                    }
                  </div>
                  <Button onClick={() => handleViewBooking(booking)} theme="primary">
                    View
                  </Button>
                </li>
              ))}
            </ul>
            <Pager
              totalItems={approvedBookings.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>

      <div className="section eventbrite-events">
        <h3>Eventbrite Events</h3>
        {events.length === 0 ? (
          <p>No Eventbrite events found.</p>
        ) : (
          <>
            <ul>
              {paginatedEvents.map((event) => (
                <li key={event.id} className="eventbrite-item">
                  <div className="event-info">
                    <p>{event.title}</p>
                    <p>{event.formattedDate}</p>
                    {event.ticketLink && (
                      <p>
                        <a href={event.ticketLink} target="_blank" rel="noopener noreferrer">
                          Tickets
                        </a>
                      </p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <Pager
              totalItems={events.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentEventPage}
              onPageChange={(page) => handlePageChange("event", page)}
            />
          </>
        )}
      </div>

      <ViewBookingModal
        isOpen={viewModalOpen}
        closeModal={() => setViewModalOpen(false)}
        bookingDetails={viewBookingDetails || emptyBookingData}
        refreshBookings={refreshBookings}
      />

      <ContractModal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        onSubmit={submitApproval}
        bookingDetails={selectedBooking}
      />
    </div>
  );
};

export default BookingsSection;
