import React from "react";
import {Modal, Select, IconTextInput} from "../../../components";
import "./EditImageModal.scss";

interface GalleryImage {
  id: string;
  imageUrl: string;
  author?: string;
  authorLink?: string;
  artist?: string;
  artistLink?: string;
  status?: "draft" | "published";
}

interface WidgetControl {
  id: number;
  label: string;
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  onClick: () => void;
}

interface EditImageModalProps {
  selectedImage: GalleryImage | null;
  authors: { name: string; link?: string }[];
  artists: { name: string; link?: string }[];
  author: string;
  authorLink: string;
  artist: string;
  artistLink: string;
  setAuthor: (value: string) => void;
  setAuthorLink: (value: string) => void;
  setArtist: (value: string) => void;
  setArtistLink: (value: string) => void;
  handleAuthorChange: (value: string | number) => void;
  handleArtistChange: (value: string | number) => void;
  handleSaveChanges: (data: {
    author: string;
    authorLink: string;
    artist: string;
    artistLink: string;
  }) => void;
  handleDeleteImage: () => void;
  handlePublishImage: () => void;
  handleUnpublishImage: () => void;
  closeModal: () => void;
}

const EditImageModal: React.FC<EditImageModalProps> = ({
  selectedImage,
  authors,
  artists,
  author,
  authorLink,
  artist,
  artistLink,
  setAuthor,
  setAuthorLink,
  setArtist,
  setArtistLink,
  handleAuthorChange,
  handleArtistChange,
  handleSaveChanges,
  handleDeleteImage,
  handlePublishImage,
  handleUnpublishImage,
  closeModal,
}) => {
  const modalControls: WidgetControl[] = [
    {
      id: 1,
      label: "Save Changes",
      onClick: () =>
        handleSaveChanges({
          author,
          authorLink,
          artist,
          artistLink,
        }),
      theme: "success",
    },
    {
      id: 2,
      label: selectedImage?.status === "published" ? "Unpublish Image" : "Publish Image",
      onClick: selectedImage?.status === "published" ? handleUnpublishImage : handlePublishImage,
      theme: "warning",
    },
    {
      id: 3,
      label: "Delete Image",
      onClick: handleDeleteImage,
      theme: "error",
    },
    {
      id: 4,
      label: "Cancel",
      onClick: closeModal,
      theme: "secondary",
    },
  ];

  const renderFormGroup = (label: string, value: string, placeholder: string, onChange: (value: string) => void, selectOptions?: { value: string; label: string }[], selectOnChange?: (value: string | number) => void) => (
    <div className="form-group">
      {selectOptions && selectOnChange && (
        <Select
          title={`Select Existing ${label}`}
          options={selectOptions}
          value={value}
          onChange={selectOnChange}
        />
      )}
      <IconTextInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );

  return (
    <Modal className="edit-image-modal" title="Edit Image Details" closeModal={closeModal} modalControls={modalControls}>
      {renderFormGroup("Author", author, "Author Name", setAuthor, authors.map((a) => ({ value: a.name, label: a.name })), handleAuthorChange)}
      {renderFormGroup("Author Link", authorLink, "Author Link (e.g., Instagram URL)", setAuthorLink)}
      {renderFormGroup("Artist", artist, "Artist Name", setArtist, artists.map((a) => ({ value: a.name, label: a.name })), handleArtistChange)}
      {renderFormGroup("Artist Link", artistLink, "Artist Link (e.g., Website URL)", setArtistLink)}
    </Modal>
  );
};

export default EditImageModal;
