import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import Button from "../../Button/Button";
import { formatDate } from "../../../../Utils/formatter";
import { BookingData } from "../../../../APIs/BookingsAPI";
import "./ContractModal.scss";
import IconTextInput from "../../IconTextInput/IconTextInput";
import { PdfData } from "../../../../Utils/pdfGen";

interface ContractModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (data: PdfData) => void;
    bookingDetails: BookingData;
}

const ContractModal: React.FC<ContractModalProps> = ({
    isOpen,
    closeModal,
    onSubmit,
    bookingDetails,
}) => {
    const calculateDeposit = (rentalFee: number) => (rentalFee * 0.1).toFixed(2);

    const calculateDepositDueDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 5);
        return date.toISOString().split("T")[0];
    };

    const calculateBalanceDueDate = (eventDate: string | null) => {
        if (!eventDate) {
            console.warn("Invalid event date provided for balance due date calculation.");
            return "";
        }

        const date = new Date(eventDate);
        if (isNaN(date.getTime())) {
            console.warn("Invalid event date provided for balance due date calculation.");
            return "";
        }

        date.setDate(date.getDate() - 7);
        return date.toISOString().split("T")[0];
    };

    const [formData, setFormData] = useState<PdfData>({
        date: "",
        client: {
            name: "",
            email: "",
        },
        eventDetails: {
            name: "",
            dates: "",
            startTime: "",
            endTime: "23:00",
            loadInTime: "19:00",
        },
        paymentTerms: {
            rentalFee: "300.00",
            deposit: "30.00",
            balanceDue: "300.00",
            dueDate: "",
            depositDueDate: "",
        },
        cancellationPolicy: {
            clientPolicy: "",
            ownerPolicy: "",
        },
        additionalServices: {
            soundTech: bookingDetails?.additionalServices?.soundTech || false,
            doorPerson: bookingDetails?.additionalServices?.doorPerson || false,
        },
    });

    useEffect(() => {
        if (bookingDetails) {
            const depositDueDate = calculateDepositDueDate();
            const balanceDueDate = calculateBalanceDueDate(bookingDetails.date);

            setFormData((prev) => ({
                ...prev,
                date: bookingDetails.date,
                client: {
                    name: bookingDetails.name,
                    email: bookingDetails.contactEmail,
                },
                eventDetails: {
                    ...prev.eventDetails,
                    name: bookingDetails.name,
                    dates: bookingDetails.date,
                    startTime: bookingDetails.time,
                },
                paymentTerms: {
                    ...prev.paymentTerms,
                    dueDate: balanceDueDate,
                    depositDueDate: depositDueDate,
                },
                cancellationPolicy: {
                    clientPolicy: "Clients must notify the venue of cancellations at least 14 calendar days prior to the event to qualify for a full refund of the deposit. Cancellations made less than 14 calendar days before the event will result in forfeiture of the deposit. All refunds will be processed within 14 business days from the date of cancellation.",
                    ownerPolicy: "The Owner reserves the right to cancel the booking due to unforeseen circumstances. These circumstances include, but are not limited to, natural disasters, government restrictions, or emergencies that render the venue unusable. In such cases, the Client will receive a full refund of the deposit and any other payments made. Refunds will be processed within 14 business days from the date of cancellation",
                },
                additionalServices: bookingDetails.additionalServices || {},
            }));
            updateRentalFee(bookingDetails.additionalServices);
        }
    }, [bookingDetails]);

    const updateRentalFee = (additionalServices: { soundTech: boolean; doorPerson: boolean }) => {
        const baseFee = 300; // Base rental fee
        const additionalFeePerService = 50;
        const additionalFee =
            (additionalServices.soundTech ? additionalFeePerService : 0) +
            (additionalServices.doorPerson ? additionalFeePerService : 0);
    
        const newRentalFee = baseFee + additionalFee; // Total rental fee
        const deposit = parseFloat(calculateDeposit(newRentalFee)); // Ensure deposit is a number
        const balance = (newRentalFee - deposit).toFixed(2); // Calculate balance and format
    
        setFormData((prev) => ({
            ...prev,
            paymentTerms: {
                ...prev.paymentTerms,
                rentalFee: newRentalFee.toFixed(2), // Ensure rental fee is formatted
                deposit: deposit.toFixed(2), // Ensure deposit is formatted
                balanceDue: balance, // Balance is already formatted
            },
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
    
        setFormData((prev) => {
            const updatedFormData = { ...prev };
    
            if (type === "checkbox") {
                const checkbox = e.target as HTMLInputElement;
                updatedFormData.additionalServices[name as keyof typeof prev.additionalServices] = checkbox.checked;
    
                updateRentalFee(updatedFormData.additionalServices);
            } else if (name in prev.paymentTerms) {
                updatedFormData.paymentTerms[name as keyof typeof prev.paymentTerms] = value;
                if (name === "rentalFee") {
                    const rentalFee = parseFloat(value) || 0;
                    updatedFormData.paymentTerms.deposit = calculateDeposit(rentalFee);
                    updatedFormData.paymentTerms.balanceDue = (
                        rentalFee - parseFloat(updatedFormData.paymentTerms.deposit)
                    ).toFixed(2);
                }
            } else if (name in prev.eventDetails) {
                updatedFormData.eventDetails[name as keyof typeof prev.eventDetails] = value;
            } else if (name in prev.client) {
                updatedFormData.client[name as keyof typeof prev.client] = value;
            } else if (name in prev.cancellationPolicy) {
                updatedFormData.cancellationPolicy[name as keyof typeof prev.cancellationPolicy] = value;
            }
    
            return updatedFormData;
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        closeModal();
    };

    return isOpen ? (
        <Modal title="Venue Booking Contract Details" closeModal={closeModal}>
            <form onSubmit={handleSubmit} className="contract-form">
                <h3>Client Information</h3>
                <label htmlFor="clientName">Client Name</label>
                <IconTextInput
                    type="text"
                    name="name"
                    placeholder="Client Name"
                    value={formData.client.name}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="clientEmail">Client Email</label>
                <IconTextInput
                    type="email"
                    name="email"
                    placeholder="Client Email"
                    value={formData.client.email}
                    onChange={handleChange}
                    required
                />

                <h3>Event Information</h3>
                <label htmlFor="eventName">Event Name</label>
                <IconTextInput
                    type="text"
                    name="name"
                    placeholder="Event Name"
                    value={formData.eventDetails.name}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="eventDates">Event Date</label>
                <IconTextInput
                    type="text"
                    name="dates"
                    placeholder="Event Date"
                    value={formatDate(formData.eventDetails.dates)}
                    onChange={handleChange}
                    required
                />

                <h3>Cancellation Policy</h3>
                <label htmlFor="clientPolicy">Client Cancellation Policy</label>
                <textarea
                    title="clientPolicy"
                    name="clientPolicy"
                    value={formData.cancellationPolicy.clientPolicy}
                    onChange={handleChange}
                    rows={6}
                    required
                />
                <label htmlFor="ownerPolicy">Owner Cancellation Policy</label>
                <textarea
                    name="ownerPolicy"
                    title="ownertPolicy"
                    value={formData.cancellationPolicy.ownerPolicy}
                    onChange={handleChange}
                    rows={6}
                    required
                />

                <h3>Payment Details</h3>
                <label htmlFor="rentalFee">Rental Fee</label>
                <IconTextInput
                    type="text"
                    name="rentalFee"
                    placeholder="Rental Fee"
                    value={formData.paymentTerms.rentalFee}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="deposit">Deposit</label>
                <IconTextInput
                    type="text"
                    name="deposit"
                    placeholder="Deposit"
                    value={formData.paymentTerms.deposit}
                    readOnly
                />
                <label htmlFor="depositDueDate">Deposit Due Date</label>
                <IconTextInput
                    type="text"
                    name="deposit"
                    placeholder="Deposit"
                    value={formData.paymentTerms.depositDueDate}
                    readOnly
                />
                <label htmlFor="totalDue">Total Due After Deposit</label>
                <IconTextInput
                    type="text"
                    name="deposit"
                    placeholder="Deposit"
                    value={formData.paymentTerms.balanceDue}
                    readOnly
                />
                <label htmlFor="totalDueDate">Total Due Date</label>
                <IconTextInput
                    type="text"
                    name="totalDueDate"
                    placeholder="Total Due Date"
                    value={formData.paymentTerms.dueDate}
                    readOnly
                />

                <h3>Additional Services</h3>
                <label>
                    <input
                        type="checkbox"
                        name="soundTech"
                        checked={formData.additionalServices.soundTech}
                        onChange={handleChange}
                    />
                    Sound Tech (+$50)
                </label>
                <label>
                    <input
                        type="checkbox"
                        name="doorPerson"
                        checked={formData.additionalServices.doorPerson}
                        onChange={handleChange}
                    />
                    Door Person (+$50)
                </label>

                <div className="form-actions">
                    <Button type="submit" theme="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    ) : null;
};

export default ContractModal;



