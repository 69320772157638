import { useEffect, useRef, ReactNode } from "react";
import SmIconButton from "../SmallIconButton/SmallIconButton";
import { FaTimes } from "react-icons/fa";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import "./Modal.scss";
import Button from "../Button/Button";

interface WidgetControl {
  id: number;
  label: string;
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  disabled?: boolean;
  onClick: () => void;
}

interface WidgetModalProps {
  title?: string;
  closeModal: () => void;
  children?: ReactNode;
  modalControls?: WidgetControl[];
  className?: string;
}

const Modal: React.FC<WidgetModalProps> = ({
  title,
  closeModal,
  children,
  modalControls,
  className
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    const focusableElements = modalRef.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ) as NodeListOf<HTMLElement>;

    if (!focusableElements || focusableElements.length === 0) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const trapFocus = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
        }
      } else if (e.key === "Escape") {
        closeModal();
      }
    };

    modalRef.current?.addEventListener("keydown", trapFocus);

    return () => {
      modalRef.current?.removeEventListener("keydown", trapFocus);
    };
  }, [closeModal]);

  return ReactDOM.createPortal(
    <motion.div
      className={`modal ${className || ""}`}
      role="dialog"
      aria-labelledby="modalTitle"
      aria-modal="true"
      ref={modalRef}
      tabIndex={-1}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="modal__content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="modal__header">
          <h2 id="modalTitle">{title}</h2>
          <SmIconButton
            icon={FaTimes}
            onClick={closeModal}
            className="modal__close-btn"
            theme="error"
            ariaLabel="Close modal"
          />
        </div>
        <div className="modal__body">{children}</div>
        <div className="modal__footer">
          {modalControls?.map((control) => (
            <Button
              key={control.id}
              onClick={control.onClick}
              className="modal__control-btn"
              theme={control.theme ?? "primary"}
              disabled={control.disabled}
            >
              {control.label}
            </Button>
          ))}
        </div>
      </motion.div>
    </motion.div>,
    document.getElementById("widget-portal") as HTMLElement
  );
};

export default Modal;
