import React, { useState } from "react";
import "./Header.scss";
import { links } from "../../Utils/Links";
import { NavLink } from "react-router-dom";
import { socialLinks } from "../../Utils/SocialLinks";
import { useAuth } from "../../Utils/Auth/AuthContext";
import { FaBars, FaTimes } from "react-icons/fa";
import Button from "../UI-Elements/Button/Button";
import SmIconButton from "../UI-Elements/SmallIconButton/SmallIconButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {
    await logout();
  };

  return (
    <header className="header">
      <nav className="header-nav">
        {isAuthenticated && (
          <Button onClick={handleSignOut} className="signout-button">
            Sign Out
          </Button>
        )}
        <div className="titleImage"></div>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${isOpen ? "open" : ""}`}>
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                to={link.href}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                {link.text}
              </NavLink>
            </li>
          ))}
          {isAuthenticated && (
            <li>
              <NavLink
                to="/dashboard/home"
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Dashboard
              </NavLink>
            </li>
          )}
        </ul>
      </nav>

      {isOpen && (
        <div className="mobile-nav">
          <div className="mobile-nav-content">
            <div className="mobile-logo">
              <div className="titleImageMobile"></div>
            </div>
            <SmIconButton
              theme="error"
              icon={FaTimes}
              onClick={toggleMenu}
              className="mobileHamburger"
            />
            <ul className="mobile-nav-links">
              {links.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.href}
                    className={({ isActive }) =>
                      isActive ? "nav-item active" : "nav-item"
                    }
                    onClick={toggleMenu}
                  >
                    {link.text}
                  </NavLink>
                </li>
              ))}
              {isAuthenticated && (
                <li>
                  <NavLink
                    to="/dashboard/home"
                    className={({ isActive }) =>
                      isActive ? "nav-item active" : "nav-item"
                    }
                    onClick={toggleMenu}
                  >
                    Dashboard
                  </NavLink>
                </li>
              )}
              {isAuthenticated && (
                <li>
                  <Button onClick={handleSignOut} className="signout-button">
                    Sign Out
                  </Button>
                </li>
              )}
            </ul>
            <div className="mobile-social-links">
              {socialLinks.map((link, index) => (
                <SmIconButton
                  icon={link.icon}
                  key={index}
                  href={link.href}
                  className="footer-link"
                />
              ))}
            </div>
            <div className="mobile-footer">
              &copy; Rare Candyz 2024. All rights reserved. <br />
              <p className="credit-footer">
                Designed by{" "}
                <a
                  href="https://www.davinchiupka.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Davin Chiupka
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
