import { useState } from "react";
import { addImageDocument } from "../../APIs/ImagesAPI";
import { uploadImageToDrafts } from "../../APIs/storageAPI";
import { addAuthor, addArtist } from "../../APIs/AuthorsArtistsAPI";
import { Select, Button, IconTextInput, Spinner } from "../components";
import "./imageUpload.scss";

const ImageUpload = ({ authors, artists, setImages, images, loadAuthorsAndArtists }) => {
  const [imageFile, setImageFile] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const fileSizeLimit = 5 * 1024 * 1024;

  const resetForm = () => {
    setImageFile(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
    setMessage({ type: "", text: "" });
  };

  const validateInput = () => {
    if (!navigator.onLine) return "You are offline. Please check your internet connection.";
    if (!imageFile) return "Please select an image.";
    if (imageFile.size > fileSizeLimit) return "File size exceeds the 5MB limit.";
    if (!["image/jpeg", "image/png", "image/jpg"].includes(imageFile.type)) return "Invalid file type. Please upload a JPEG, JPG, or PNG.";
    if (!author || !artist) return "Please ensure all required fields are filled.";
    return null;
  };

  const handleImageUpload = async () => {
    const validationError = validateInput();
    if (validationError) return setMessage({ type: "error", text: validationError });

    setLoading(true);

    try {
      const authorAdded = !authors.find((a) => a.name === author) && await addAuthor({ name: author, link: authorLink });
      const artistAdded = !artists.find((a) => a.name === artist) && await addArtist({ name: artist, link: artistLink });

      if (authorAdded || artistAdded) await loadAuthorsAndArtists();

      const { downloadURL } = await uploadImageToDrafts(imageFile);
      const newImage = await addImageDocument({
        imageUrl: downloadURL,
        author,
        authorLink,
        artist,
        artistLink,
        status: "draft",
      });

      setImages([...images, newImage]);
      setMessage({ type: "success", text: "Image uploaded to drafts successfully!" });
      resetForm();
    } catch (error) {
      setMessage({ type: "error", text: "An error occurred during upload. Please try again." });
      console.error("Upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value, setFunc, items) => {
    const selectedItem = items.find((item) => item.name === value);
    setFunc(selectedItem ? selectedItem.name : value);
    if (setFunc === setAuthor) setAuthorLink(selectedItem ? selectedItem.link : "");
    if (setFunc === setArtist) setArtistLink(selectedItem ? selectedItem.link : "");
  };

  return (
    <div className="image-upload">
      <h2>Upload Image</h2>
      <form>
        <div className="form-group">
          <input
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            onChange={(e) => setImageFile(e.target.files[0])}
            className="file-input"
          />
        </div>
        <div className="form-group">
          <Select
            label="Select Existing Author"
            options={authors.map(({ name }) => ({ value: name, label: name }))}
            value={author}
            onChange={(value) => handleSelectChange(value, setAuthor, authors)}
          />
          <IconTextInput
            type="text"
            placeholder="New Author Name"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <IconTextInput
            type="text"
            placeholder="Author Link (e.g., Instagram URL)"
            value={authorLink}
            onChange={(e) => setAuthorLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <Select
            label="Select Existing Artist"
            options={artists.map(({ name }) => ({ value: name, label: name }))}
            value={artist}
            onChange={(value) => handleSelectChange(value, setArtist, artists)}
          />
          <IconTextInput
            type="text"
            placeholder="New Artist Name"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </div>
        <div className="form-group">
          <IconTextInput
            type="text"
            placeholder="Artist Link (e.g., Website URL)"
            value={artistLink}
            onChange={(e) => setArtistLink(e.target.value)}
          />
        </div>
        <Button onClick={handleImageUpload}>
          {message.type === "error" ? "Retry Upload" : "Upload Image"}
        </Button>
        {loading && <div className="spinner"></div>}
        {message.text && <p className={`${message.type}-message`}>{message.text}</p>}
      </form>
    </div>
  );
};

export default ImageUpload;
