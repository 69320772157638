import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "../../../components";
import {
    validateYouTubeLink,
    validateInstagramLink,
    validateFacebookLink,
    validateSpotifyLink,
} from "../../../../Utils/linkValidator";
import "./RequestBookingModal.scss";

interface RequestBookingModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (
        bandName: string,
        contactEmail: string,
        description: string,
        time: string,
        additionalServices: { soundTech: boolean; doorPerson: boolean },
        socialLinks: { youtube: string; instagram: string; facebook: string; spotify: string },
    ) => void;
    selectedDate: string | null;
}

const RequestBookingModal: React.FC<RequestBookingModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    selectedDate,
}) => {
    const [name, setName] = useState<string>("");
    const [contactEmail, setContactEmail] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [time, setTime] = useState<string>("");
    const [additionalServices, setAdditionalServices] = useState({
        soundTech: false,
        doorPerson: false,
    });
    const [socialLinks, setSocialLinks] = useState({
        youtube: "",
        instagram: "",
        facebook: "",
        spotify: "",
    });
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [timeError, setTimeError] = useState<string | null>(null);

    const validateTime = (time: string): boolean => {
        const [hours, minutes] = time.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        const startLimit = 17 * 60; // 5:00 PM
        const endLimit = 23 * 60; // 11:00 PM
        return totalMinutes >= startLimit && totalMinutes <= endLimit;
    };

    useEffect(() => {
        if (time && !validateTime(time)) {
            setTimeError("Start time must be between 5:00 PM and 11:00 PM.");
        } else {
            setTimeError(null);
        }

        const isFormValid =
            name.trim() &&
            contactEmail.trim() &&
            description.trim() &&
            time.trim() &&
            captchaToken !== null &&
            !timeError;
        setCanSubmit(isFormValid || false);
    }, [name, contactEmail, description, time, captchaToken, timeError]);

    const handleServiceChange = (service: "soundTech" | "doorPerson") => {
        setAdditionalServices((prev) => ({
            ...prev,
            [service]: !prev[service],
        }));
    };

    const handleSocialLinkChange = (field: keyof typeof socialLinks, value: string) => {
        setSocialLinks((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const cleanSocialLinks = () => {
        const trimmedLinks = {
            youtube: socialLinks.youtube.trim(),
            instagram: socialLinks.instagram.trim(),
            facebook: socialLinks.facebook.trim(),
            spotify: socialLinks.spotify.trim(),
        };

        if (
            (trimmedLinks.youtube && !validateYouTubeLink(trimmedLinks.youtube)) ||
            (trimmedLinks.instagram && !validateInstagramLink(trimmedLinks.instagram)) ||
            (trimmedLinks.facebook && !validateFacebookLink(trimmedLinks.facebook)) ||
            (trimmedLinks.spotify && !validateSpotifyLink(trimmedLinks.spotify))
        ) {
            alert("One or more social media links are invalid. Please check and try again.");
            return null;
        }

        return trimmedLinks;
    };

    const handleSubmit = async () => {
        if (isSubmitting || timeError) return;
        setIsSubmitting(true);

        try {
            const cleanedLinks = cleanSocialLinks();
            if (!cleanedLinks) return;

            onSubmit(name, contactEmail, description, time, additionalServices, cleanedLinks);

            setName("");
            setContactEmail("");
            setDescription("");
            setTime("");
            setAdditionalServices({ soundTech: false, doorPerson: false });
            setSocialLinks({ youtube: "", instagram: "", facebook: "", spotify: "" });
            setCaptchaToken(null);
            onClose();
        } catch (error) {
            console.error("Submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen || !selectedDate) return null;

    return (
        <Modal
            title={`Request Booking - ${selectedDate}`}
            closeModal={onClose}
            modalControls={[
                {
                    id: 1,
                    label: "Submit",
                    theme: "primary",
                    onClick: handleSubmit,
                    disabled: !canSubmit,
                },
                {
                    id: 2,
                    label: "Cancel",
                    theme: "error",
                    onClick: onClose,
                },
            ]}
        >
            <div className="request-booking-modal">
                <p>
                    Submitting a booking request helps us understand your needs but does not guarantee availability.
                </p>
                <form>
                    <div className="form-group">
                        <label htmlFor="bandName">Your Name</label>
                        <input
                            type="text"
                            id="bandName"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="contactEmail">Contact Email</label>
                        <input
                            type="email"
                            id="contactEmail"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                            placeholder="Enter your email address"
                            required
                        />
                    </div>
                    <p>To ensure a smooth process, please provide as much detail as possible about your event.</p>
                    <div className="form-group">
                        <label htmlFor="description">Event Description</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Tell us about your event."
                            rows={4}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="time">Expected Start Time</label>
                        <input
                            type="time"
                            id="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            required
                        />
                        {timeError && <p className="error-text">{timeError}</p>}
                        <p>Please note: Events are expected to conclude by 11:00 PM.</p>
                    </div>
                    <h3>Social Media Links</h3>
                    <div className="form-group">
                        <label htmlFor="youtube">YouTube</label>
                        <input
                            type="url"
                            id="youtube"
                            value={socialLinks.youtube}
                            onChange={(e) => handleSocialLinkChange("youtube", e.target.value)}
                            placeholder="Enter YouTube link"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="instagram">Instagram</label>
                        <input
                            type="url"
                            id="instagram"
                            value={socialLinks.instagram}
                            onChange={(e) => handleSocialLinkChange("instagram", e.target.value)}
                            placeholder="Enter Instagram link"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="facebook">Facebook</label>
                        <input
                            type="url"
                            id="facebook"
                            value={socialLinks.facebook}
                            onChange={(e) => handleSocialLinkChange("facebook", e.target.value)}
                            placeholder="Enter Facebook link"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="spotify">Spotify</label>
                        <input
                            type="url"
                            id="spotify"
                            value={socialLinks.spotify}
                            onChange={(e) => handleSocialLinkChange("spotify", e.target.value)}
                            placeholder="Enter Spotify link"
                        />
                    </div>
                    <p>
                        <strong>Standard Booking Fee: $300</strong>
                    </p>
                    <p>
                        Details about booking fees will be discussed once your request is confirmed.
                    </p>

                    <div className="form-group">
                        <h3>Request Additional Services</h3>
                        <p>Enhance your event with optional services:</p>

                        <div className="additional-services">
                            <label>
                                <input
                                    type="checkbox"
                                    name="soundTech"
                                    checked={additionalServices.soundTech}
                                    onChange={() => handleServiceChange("soundTech")}
                                />
                                Sound Tech ($50)
                            </label>

                            <label>
                                <input
                                    type="checkbox"
                                    name="doorPerson"
                                    checked={additionalServices.doorPerson}
                                    onChange={() => handleServiceChange("doorPerson")}
                                />
                                Door Person ($50)
                            </label>
                        </div>
                    </div>

                    <div className="form-group">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                            onChange={(token) => setCaptchaToken(token)}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RequestBookingModal;
