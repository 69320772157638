export const validateYouTubeLink = (url: string): boolean => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return youtubeRegex.test(url);
};

export const validateInstagramLink = (url: string): boolean => {
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/;
    return instagramRegex.test(url);
};

export const validateFacebookLink = (url: string): boolean => {
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/;
    return facebookRegex.test(url);
};

export const validateSpotifyLink = (url: string): boolean => {
    const spotifyRegex = /^(https?:\/\/)?(open\.)?spotify\.com\/.+$/;
    return spotifyRegex.test(url);
};

export const validateAllLinks = (links: { youtube: string; instagram: string; facebook: string; spotify: string }): boolean => {
    return (
        (!links.youtube || validateYouTubeLink(links.youtube)) &&
        (!links.instagram || validateInstagramLink(links.instagram)) &&
        (!links.facebook || validateFacebookLink(links.facebook)) &&
        (!links.spotify || validateSpotifyLink(links.spotify))
    );
};
