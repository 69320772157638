import React, { useState } from "react";
import {Button, Modal} from "../../../components";
import { updateVideo, deleteVideo } from "../../../../APIs/VideosAPI";
import "./EditYoutubeModal.scss";
import { VideoData } from "../../../../types";


interface EditYouTubeVideoModalProps {
  selectedVideo: VideoData;
  closeModal: () => void;
}

const EditYouTubeVideoModal: React.FC<EditYouTubeVideoModalProps> = ({
  selectedVideo,
  closeModal,
}) => {
  const [videoName, setVideoName] = useState<string>(selectedVideo.videoName);
  const [videoUrl, setVideoUrl] = useState<string>(selectedVideo.videoUrl);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateVideo(selectedVideo.id, { videoName, videoUrl });
      alert("Video updated successfully.");
      closeModal();
    } catch (error) {
      console.error("Error updating video:", error);
      alert("Failed to update the video.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this video?")) return;
    try {
      await deleteVideo(selectedVideo.id);
      alert("Video deleted successfully.");
      closeModal();
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Failed to delete the video.");
    }
  };

  return (
    <Modal className="edit-video-modal" title="Edit YouTube Video" closeModal={closeModal}>
      <div className="edit-youtube-video-modal">
        <div className="modal-content">
          <h2>Edit YouTube Video</h2>
          <div className="form-group">
            <label htmlFor="video-name">Video Name:</label>
            <textarea
              id="video-name"
              value={videoName}
              onChange={(e) => setVideoName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="video-url">Video URL:</label>
            <textarea
              id="video-url"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </div>
          <div className="modal-actions">
            <Button onClick={handleSave} theme="success" disabled={isSaving}>
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
            <Button onClick={handleDelete} theme="error">
              Delete Video
            </Button>
            <Button onClick={closeModal} theme="secondary">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditYouTubeVideoModal;
