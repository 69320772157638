import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

export const uploadImageToDrafts = (file: File) => {
  const draftStorageRef = ref(storage, `drafts/${file.name}`);
  const uploadTask = uploadBytesResumable(draftStorageRef, file);

  return new Promise<{ downloadURL: string }>((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve({ downloadURL });
      }
    );
  });
};
