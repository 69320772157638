import React, { useState } from 'react';
import Button from '../Button/Button';
import SmIconButton from '../SmallIconButton/SmallIconButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './Pager.scss';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pager: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  className,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [showAll, setShowAll] = useState(false);

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handleClick = (page: number) => {
    if (page !== currentPage) onPageChange(page);
  };

  return (
    <div className={`pagination ${className}`}>
      {/* Previous Button */}
      <div className="pagination-controls">
        <SmIconButton
          icon={FaArrowLeft}
          theme="primary"
          className="pagination-control-button"
          disabled={currentPage === 1}
          onClick={() => handleClick(currentPage - 1)}
        />
      </div>

      {/* Page Numbers */}
      <div className="pagination-buttons">
        {pages.length > 7 && !showAll ? (
          <>
            <Button
              theme={currentPage === 1 ? 'secondary' : 'primary'}
              onClick={() => handleClick(1)}
              className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
            >
              1
            </Button>

            {currentPage > 4 && <span className="pagination-dots">...</span>}

            {pages
              .filter(
                (page) =>
                  page === currentPage || page === currentPage - 1 || page === currentPage + 1
              )
              .map((page) => (
                <Button
                  key={page}
                  theme={page === currentPage ? 'secondary' : 'primary'}
                  onClick={() => handleClick(page)}
                  className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                >
                  {page}
                </Button>
              ))}

            {currentPage < totalPages - 3 && <span className="pagination-dots">...</span>}

            <Button
              theme={currentPage === totalPages ? 'secondary' : 'primary'}
              onClick={() => handleClick(totalPages)}
              className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
            >
              {totalPages}
            </Button>

            {/* View More Button */}
            <Button
              theme="primary"
              onClick={() => setShowAll(true)}
              className="pagination-button view-more"
            >
              View More
            </Button>
          </>
        ) : (
          pages.map((page) => (
            <Button
              key={page}
              theme={page === currentPage ? 'secondary' : 'primary'}
              onClick={() => handleClick(page)}
              className={`pagination-button ${page === currentPage ? 'active' : ''}`}
            >
              {page}
            </Button>
          ))
        )}
      </div>

      {/* Next Button */}
      <div className="pagination-controls">
        <SmIconButton
          icon={FaArrowRight}
          theme="primary"
          className="pagination-control-button"
          disabled={currentPage === totalPages}
          onClick={() => handleClick(currentPage + 1)}
        />
      </div>
    </div>
  );
};

export default Pager;
