import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Utils/Auth/AuthContext";

import AnimatedRoutes from "./Components/Util/AnimatedRoutes";
import ScrollToTop from "./Hooks/ScrollToTop";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import ScrollToTopButton from "./Components/UI-Elements/ScrollToTopButton/ScrollToTopButton";

const App = () => {
  return (
        <AuthProvider>
          <Router>
            <ScrollToTop />
            <Header />
            <AnimatedRoutes />
            <div id="widget-portal"></div>
            <div id="chip-portal"></div>
            <Footer />
          </Router>
          <ScrollToTopButton />
        </AuthProvider>
  );
};

export default App;
