import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ProtectedRoute from "../../Utils/Auth/AuthRoute";
import HomePage from "../../Pages/Home/Home";
import InfoPage from "../../Pages/Info/Info";
import GalleryPage from "../../Pages/Gallery/Gallery";
import LoginPage from "../../Pages/Login/Login";
import DashboardPage from "../../Pages/DashboardPage/DashBoard";

import ImagesSection from "../../Pages/DashboardPage/Images/Images";
import VideosSection from "../../Pages/DashboardPage/Videos/Videos";
import BookingsSection from "../../Pages/DashboardPage/Bookings/Bookings";

import PageNotFound from "../../Pages/PageNotFound/PageNotFound";
import CalendarPage from "../../Pages/Calendar/Calendar";
import DashboardHome from "../../Pages/DashboardPage/Home/DashboardHome";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardPage />}>
            <Route path="home" element={<DashboardHome />} />
            <Route path="images" element={<ImagesSection />} />
            <Route path="videos" element={<VideosSection />} />
            <Route path="bookings" element={<BookingsSection />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
