import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { VideoData } from "../types";
import { db } from "../firebase";

/**
 * Fetch all videos from Firestore.
 */
export const fetchVideos = async (): Promise<VideoData[]> => {
  try {
    const videoCollection = collection(db, "galleryYoutubeVideos");
    const videoSnapshot = await getDocs(videoCollection);
    return videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as VideoData[];
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error;
  }
};

/**
 * Update a video in Firestore.
 * @param videoId The ID of the video to update.
 * @param updatedData The updated video data.
 */
export const updateVideo = async (videoId: string, updatedData: Partial<VideoData>): Promise<void> => {
  try {
    const videoDocRef = doc(db, "galleryYoutubeVideos", videoId);
    await updateDoc(videoDocRef, updatedData);
    console.log(`Video with ID ${videoId} updated successfully.`);
  } catch (error) {
    console.error("Error updating video:", error);
    throw error;
  }
};

/**
 * Delete a video from Firestore.
 * @param videoId The ID of the video to delete.
 */
export const deleteVideo = async (videoId: string): Promise<void> => {
  try {
    const videoDocRef = doc(db, "galleryYoutubeVideos", videoId);
    await deleteDoc(videoDocRef);
    console.log(`Video with ID ${videoId} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting video:", error);
    throw error;
  }
};
