import React, { useState, useEffect } from "react";
import {
  EditImageModal,
  Pager,
  IconTextInput,
  Select,
  ImageUpload,
} from "../../../Components/components";
import {
  fetchImages,
  publishImage,
  unpublishImage,
  deleteImage,
} from "../../../APIs/ImagesAPI";
import { fetchAuthorsAndArtists } from "../../../APIs/AuthorsArtistsAPI";
import { GalleryImage, AuthorArtist } from "../../../types";
import { FaSearch } from "react-icons/fa";
import "./Images.scss";

const statusFilterOptions = [
  { value: "all", label: "All" },
  { value: "published", label: "Published" },
  { value: "draft", label: "Draft" },
];

const ImagesSection: React.FC = () => {
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [authors, setAuthors] = useState<AuthorArtist[]>([]);
  const [artists, setArtists] = useState<AuthorArtist[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<"all" | "published" | "draft">("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [, setAuthor] = useState("");
  const [, setAuthorLink] = useState("");
  const [, setArtist] = useState("");
  const [, setArtistLink] = useState("");

  const itemsPerPage = 12;

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const fetchedImages = await fetchImages();
        setImages(fetchedImages);

        const { authors, artists } = await fetchAuthorsAndArtists();
        setAuthors(authors);
        setArtists(artists);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadInitialData();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (value: string | number) => {
    setStatusFilter(value as "all" | "published" | "draft");
    setCurrentPage(1);
  };

  const handleImageClick = (image: GalleryImage) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const handleImageUpdate = async (updatedImage: Partial<GalleryImage>) => {
    if (selectedImage) {
      try {
        const updatedImages = images.map((img) =>
          img.id === selectedImage.id ? { ...img, ...updatedImage } : img
        );
        setImages(updatedImages);
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error updating image:", error);
      }
    }
  };

  const handleImageAction = async (
    image: GalleryImage,
    action: "publish" | "unpublish" | "delete"
  ) => {
    try {
      if (action === "publish") {
        const updatedImage = await publishImage(image);
        setImages(images.map((img) => (img.id === updatedImage.id ? updatedImage : img)));
      } else if (action === "unpublish") {
        const updatedImage = await unpublishImage(image);
        setImages(images.map((img) => (img.id === updatedImage.id ? updatedImage : img)));
      } else if (action === "delete") {
        if (window.confirm("Are you sure you want to delete this image?")) {
          await deleteImage(image);
          setImages(images.filter((img) => img.id !== image.id));
        }
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error during image ${action}:`, error);
    }
  };

  const handleAuthorChange = (value: string | number) => {
    const selectedAuthor = authors.find((author) => author.name === value);
    if (selectedAuthor) {
      setAuthor(selectedAuthor.name);
      setAuthorLink(selectedAuthor.link || "");
    } else {
      setAuthor(String(value));
      setAuthorLink("");
    }
  };

  const handleArtistChange = (value: string | number) => {
    const selectedArtist = artists.find((artist) => artist.name === value);
    if (selectedArtist) {
      setArtist(selectedArtist.name);
      setArtistLink(selectedArtist.link || "");
    } else {
      setArtist(String(value));
      setArtistLink("");
    }
  };

  const filteredImages = images.filter((image) => {
    const matchesStatus = statusFilter === "all" || image.status === statusFilter;
    const matchesQuery =
      image.author?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      image.artist?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesQuery;
  });

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="images-section">
      <header className="section-header">
        <h2>Manage Images</h2>
        <p>Upload, view, and manage gallery images.</p>
      </header>

      <section className="add-image-section">
        <ImageUpload
          authors={authors}
          artists={artists}
          images={images}
          setImages={setImages}
          loadAuthorsAndArtists={async () => {
            const { authors, artists } = await fetchAuthorsAndArtists();
            setAuthors(authors);
            setArtists(artists);
          }}
        />
      </section>

      <section className="search-section">
        <IconTextInput
          icon={FaSearch}
          placeholder="Search by author or artist..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <Select
          options={statusFilterOptions}
          value={statusFilter}
          onChange={handleFilterChange}
          placeholder="Filter by status"
        />
      </section>

      <section className="image-grid-section">
        {filteredImages.length > 0 ? (
          <>
            <Pager
              totalItems={filteredImages.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            <ul className="image-grid">
              {paginatedImages.map((image) => (
                <li key={image.id} onClick={() => handleImageClick(image)}>
                  <img src={image.imageUrl} alt="Gallery" />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p className="no-images-message">No images match this criteria.</p>
        )}
      </section>

      {isModalOpen && selectedImage && (
        <EditImageModal
        selectedImage={selectedImage}
        authors={authors}
        artists={artists}
        author={selectedImage.author || ""}
        authorLink={selectedImage.authorLink || ""}
        artist={selectedImage.artist || ""}
        artistLink={selectedImage.artistLink || ""}
        setAuthor={setAuthor}
        setAuthorLink={setAuthorLink}
        setArtist={setArtist}
        setArtistLink={setArtistLink}
        handleAuthorChange={handleAuthorChange}
        handleArtistChange={handleArtistChange}
        handleSaveChanges={handleImageUpdate}
        handlePublishImage={() => handleImageAction(selectedImage, "publish")}
        handleUnpublishImage={() => handleImageAction(selectedImage, "unpublish")}
        handleDeleteImage={() => handleImageAction(selectedImage, "delete")}
        closeModal={() => setIsModalOpen(false)}
      />
      )}
    </div>
  );
};

export default ImagesSection;
