import React, { useState, useEffect } from "react";
import { fetchVideos } from "../../../APIs/VideosAPI";
import { VideoData } from "../../../types";
import "./Videos.scss";
import { VideoUpload, Pager, EditYoutubeModal, Button } from "../../../Components/components";

const VideosSection: React.FC = () => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState<VideoData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const itemsPerPage = 3;

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const fetchedVideos = await fetchVideos();
        setVideos(fetchedVideos);
      } catch (error) {
        setError("Failed to load videos.");
      }
    };

    loadVideos();
  }, []);

  const handleVideoUploadSuccess = async () => {
    try {
      const updatedVideos = await fetchVideos();
      setVideos(updatedVideos);
    } catch (error) {
      setError("Failed to refresh videos after upload.");
    }
  };

  const handleEditClick = (video: VideoData) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  const paginatedVideos = videos.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="videos-section">
      <header className="section-header">
        <h2>Manage Videos</h2>
        <p>Upload, view, and manage gallery videos.</p>
      </header>

      <section className="add-video-section">
        <VideoUpload onUploadSuccess={handleVideoUploadSuccess} />
      </section>

      <section className="video-grid-section">
        {error && <p className="error-message">{error}</p>}
        {videos.length > 0 ? (
          <>
            <Pager
              totalItems={videos.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            <ul className="video-grid">
              {paginatedVideos.map((video) => (
                <li key={video.id}>
                  <iframe
                    src={video.videoUrl}
                    title={video.videoName}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <p>{video.videoName}</p>
                  <Button
                    className="edit-button"
                    onClick={() => handleEditClick(video)}
                  >
                    Edit
                  </Button>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p className="no-videos-message">No videos available. Please upload a video.</p>
        )}
      </section>

      {isModalOpen && selectedVideo && (
        <EditYoutubeModal
          selectedVideo={selectedVideo}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default VideosSection;
