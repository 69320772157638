import React, { useState } from "react";
import Modal from "../Modal";
import axios from "axios";
import Alert from "../../Alert/Alert";
import { BookingData, deleteBooking, closeBooking, updateBooking, approveBooking } from "../../../../APIs/BookingsAPI";
import ContractModal from "../BookingContractModal/BookingContractModal";
import Button from "../../Button/Button";
import "./ViewBookingModal.scss";
import { formatDate, formatTimeTo12Hour } from "../../../../Utils/formatter";

interface ViewBookingModalProps {
  isOpen: boolean;
  closeModal: () => void;
  bookingDetails: BookingData;
  refreshBookings: () => void;
}

const ViewBookingModal: React.FC<ViewBookingModalProps> = ({
  isOpen,
  closeModal,
  bookingDetails,
  refreshBookings,
}) => {
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [alert, setAlert] = useState<{ type: "success" | "error"; message: string } | null>(null);

  if (!isOpen) return null;

  const {
    id,
    name,
    contactEmail,
    description,
    date,
    time,
    additionalServices,
    socialLinks,
    status,
  } = bookingDetails;

  const openContractModal = () => {
    console.log(bookingDetails);
    setIsContractModalOpen(true);
  };

  const closeContractModal = () => {
    setIsContractModalOpen(false);
  };

  const handleReopenBooking = async () => {
    if (!window.confirm("Are you sure you want to reopen this booking?")) return;

    try {
      setIsProcessing(true);
      await updateBooking(id, { status: "approved", deleteBy: "" });
      setAlert({ type: "success", message: "Booking has been reopened." });
      refreshBookings();
      closeModal();
    } catch (error) {
      console.error("Error reopening booking:", error);
      setAlert({ type: "error", message: "Failed to reopen booking. Please try again." });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleMarkCompleted = async () => {
    if (
      !window.confirm(
        "Mark this booking as completed? The booking will be scheduled for deletion in 30 days."
      )
    )
      return;

    try {
      setIsProcessing(true);
      await closeBooking(id, date);
      setAlert({ type: "success", message: "Booking marked as completed and scheduled for deletion." });
      refreshBookings();
      closeModal();
    } catch (error) {
      console.error("Error marking booking as completed:", error);
      setAlert({ type: "error", message: "Failed to mark booking as completed. Please try again." });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelBooking = async () => {
    const reason = window.prompt("Enter a reason for canceling this booking:");
  
    if (!reason) {
      setAlert({ type: "error", message: "Cancellation reason is required." });
      return;
    }
  
    if (
      !window.confirm(
        "Are you sure you want to cancel this booking? This action cannot be undone."
      )
    ) {
      return;
    }
  
    try {
      setIsProcessing(true);
  
      // Send cancellation email
      await axios.post(
        "https://sendcancellationemail-imbnnmd5ea-uc.a.run.app", // Replace with your Firebase function URL
        {
          name: bookingDetails.name,
          email: bookingDetails.contactEmail,
          date: bookingDetails.date,
          time: bookingDetails.time,
          description: bookingDetails.description,
          reason,
        }
      );
  
      // Delete the booking from Firestore
      await deleteBooking(id, date);
  
      setAlert({ type: "success", message: "Booking has been canceled, and the user has been notified." });
      refreshBookings();
      closeModal();
    } catch (error) {
      console.error("Error canceling booking:", error);
      setAlert({ type: "error", message: "Failed to cancel booking. Please try again." });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteBooking = async () => {
    if (!window.confirm("Are you sure you want to delete this booking? This action cannot be undone.")) {
      return;
    }

    try {
      setIsProcessing(true);
      await deleteBooking(id, date);
      setAlert({ type: "success", message: "Booking has been deleted." });
      refreshBookings();
      closeModal();
    } catch (error) {
      console.error("Error deleting booking:", error);
      setAlert({ type: "error", message: "Failed to delete booking. Please try again." });
    } finally {
      setIsProcessing(false);
    }
  };

  const resendContract = async (contractDetails: any) => {
      if (!bookingDetails) return;
    
      try {
        await approveBooking(bookingDetails, contractDetails);
        setAlert({ type: "success", message: "Booking approved and requester notified." });
      } catch (error) {
        console.error("Error approving booking:", error);
        setAlert({ type: "error", message: "Failed to approve booking. Please try again." });
      } finally {
        setIsContractModalOpen(false);
      }
    };

  return (
    <>
     {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          duration={5000}
        />
      )}
      <Modal
        title="View Booking Details"
        closeModal={closeModal}
        className="view-booking-modal"
      >
        <div className="view-booking-modal__content">
          <p>
            <strong>Event Host:</strong> {name}
          </p>
          <p>
            <strong>Contact Email:</strong> {contactEmail}
          </p>
          <p>
            <strong>Description:</strong> {description}
          </p>
          <p>
            <strong>Date:</strong> {formatDate(date)}
          </p>
          <p>
            <strong>Time:</strong>{" "}
            {time ? formatTimeTo12Hour(time) : "Time not specified"}
          </p>
          <p>
            <strong>Additional Services:</strong>
          </p>
          <ul>
            {additionalServices.soundTech && <li>Sound Tech</li>}
            {additionalServices.doorPerson && <li>Door Person</li>}
          </ul>
          <p>
            <strong>Social Links:</strong>
          </p>
          <ul>
            {socialLinks.facebook && (
              <li>
                <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              </li>
            )}
            {socialLinks.instagram && (
              <li>
                <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
            )}
            {socialLinks.spotify && (
              <li>
                <a href={socialLinks.spotify} target="_blank" rel="noreferrer">
                  Spotify
                </a>
              </li>
            )}
            {socialLinks.youtube && (
              <li>
                <a href={socialLinks.youtube} target="_blank" rel="noreferrer">
                  YouTube
                </a>
              </li>
            )}
          </ul>
          <p>
            <strong>Status:</strong> {status}
          </p>
        </div>
        <div className="view-booking-modal__footer">
          <Button onClick={openContractModal} className="resend-contract-btn">
            Resend Contract
          </Button>
          {status === "completed" ? (
            <>
              <Button onClick={handleReopenBooking} className="reopen-booking-btn" theme="primary">
                Reopen Booking
              </Button>
              <Button onClick={handleDeleteBooking} className="delete-booking-btn" theme="error">
                Delete Booking
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleMarkCompleted} className="mark-completed-btn" theme="success">
                Mark as Completed
              </Button>
              <Button onClick={handleCancelBooking} className="cancel-booking-btn" theme="error">
                Cancel Booking
              </Button>
            </>
          )}
        </div>
      </Modal>

      {isContractModalOpen && (
        <ContractModal
          isOpen={isContractModalOpen}
          closeModal={closeContractModal}
          onSubmit={(data) => {
            resendContract(data);
          }}
          bookingDetails={bookingDetails}
        />
      )}
    </>
  );
};

export default ViewBookingModal;
