import React, { useEffect, useState } from "react";
import { fetchAllEvents, fetchAttendees } from "../../../APIs/EventsAPI";
import { Spinner } from "../../../Components/components";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { FaCalendar, FaTicketAlt, FaDollarSign, FaUser, FaChartBar, FaStar } from "react-icons/fa";
import "./DashHome.scss";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DashboardStats: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<{
    totalEvents: number;
    totalTicketsSold: number;
    totalRevenue: string;
    totalAttendees: number;
    averageAttendance: number;
    highestAttendance: number;
    highestAttendanceEvent: string;
    lowestAttendance: number;
    lowestAttendanceEvent: string;
    topGrossingEvent: string;
    topGrossingRevenue: string;
  }>({
    totalEvents: 0,
    totalTicketsSold: 0,
    totalRevenue: "$0.00",
    totalAttendees: 0,
    averageAttendance: 0,
    highestAttendance: 0,
    highestAttendanceEvent: "",
    lowestAttendance: 0,
    lowestAttendanceEvent: "",
    topGrossingEvent: "",
    topGrossingRevenue: "$0.00",
  });

  const [eventAttendanceData, setEventAttendanceData] = useState<{
    labels: string[];
    data: number[];
  }>({ labels: [], data: [] });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStatsFromFirestore = async () => {
      const statsDoc = doc(db, "stats", "dashboard");
      const docSnap = await getDoc(statsDoc);

      if (docSnap.exists()) {
        return docSnap.data() as typeof stats;
      } else {
        return null;
      }
    };

    const fetchData = async () => {
      try {
        setLoading(true);

        const storedStats = await fetchStatsFromFirestore();

        const { events } = await fetchAllEvents();

        let attendanceData: { labels: string[]; data: number[] } = { labels: [], data: [] };

        for (const event of events) {
          try {
            const attendees = await fetchAttendees(event.id);
            attendanceData.labels.push(event.title);
            attendanceData.data.push(attendees.length);
          } catch (attendeeError) {
            console.error(`Error fetching attendees for event ${event.id}:`, attendeeError);
          }
        }

        setEventAttendanceData(attendanceData);

        if (storedStats && storedStats.totalEvents === events.length) {
          setStats(storedStats);
          return;
        }

        let totalRevenue = 0;
        let totalTicketsSold = 0;
        let highestAttendance = 0;
        let highestAttendanceEvent = "";
        let lowestAttendance = Number.MAX_SAFE_INTEGER;
        let lowestAttendanceEvent = "";
        let topGrossingRevenue = 0;
        let topGrossingEvent = "";

        for (const event of events) {
          try {
            const attendees = await fetchAttendees(event.id);
            const eventRevenue = attendees.reduce(
              (acc, attendee) => acc + (attendee.costs.gross || 0),
              0
            );
            const attendeeCount = attendees.length;

            totalTicketsSold += attendeeCount;
            totalRevenue += eventRevenue;

            if (attendeeCount > highestAttendance) {
              highestAttendance = attendeeCount;
              highestAttendanceEvent = event.title;
            }

            if (attendeeCount < lowestAttendance) {
              lowestAttendance = attendeeCount;
              lowestAttendanceEvent = event.title;
            }

            if (eventRevenue > topGrossingRevenue) {
              topGrossingRevenue = eventRevenue;
              topGrossingEvent = event.title;
            }
          } catch (attendeeError) {
            console.error(`Error fetching attendees for event ${event.id}:`, attendeeError);
          }
        }

        const totalAttendees = totalTicketsSold;
        const averageAttendance = events.length > 0 ? totalAttendees / events.length : 0;

        const newStats = {
          totalEvents: events.length,
          totalTicketsSold,
          totalRevenue: `$${(totalRevenue / 100).toFixed(2)}`,
          totalAttendees,
          averageAttendance: Math.round(averageAttendance),
          highestAttendance,
          highestAttendanceEvent,
          lowestAttendance: lowestAttendance === Number.MAX_SAFE_INTEGER ? 0 : lowestAttendance,
          lowestAttendanceEvent: lowestAttendance === Number.MAX_SAFE_INTEGER ? "N/A" : lowestAttendanceEvent,
          topGrossingEvent,
          topGrossingRevenue: `$${(topGrossingRevenue / 100).toFixed(2)}`,
        };

        await setDoc(doc(db, "stats", "dashboard"), newStats);

        setStats(newStats);
      } catch (err) {
        console.error("Error fetching dashboard stats:", err);
        setError("Failed to load stats.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const chartData = React.useMemo(() => {
    return {
      labels: eventAttendanceData.labels.map((title) =>
        title.length > 20 ? title.slice(0, 20) + "..." : title
      ),
      datasets: [
        {
          label: "Attendance",
          data: eventAttendanceData.data,
          backgroundColor: "grey",
          borderColor: "black",
          borderWidth: 1,
        },
      ],
    };
  }, [eventAttendanceData]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: { raw: any }) => `Online Tickets: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Events",
        },
        ticks: {
          callback: (value: number | string, index: number) => {
            const title = eventAttendanceData.labels[index];
            return title?.length > 20 ? `${title.slice(0, 20)}...` : title; 
          },
          maxRotation: 90, 
          minRotation: 90,
          align: "center", 
        },
      },
      y: {
        title: {
          display: true,
          text: "Ticket Sales",
        },
        beginAtZero: true,
      },
    },
  } as const;

  if (loading) {
      return <>
      <div className="loading-page">
      <Spinner size={100}/>
      <p>Loading data...</p>
      </div>
      </>;
    }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="dashboard-stats">
      <h2>Event Stats</h2>
      <div className="stats-summary">
        <div className="stat-item">
          <h3>
            <FaCalendar /> Events to date
          </h3>
          <p>{stats.totalEvents}</p>
        </div>
        <div className="stat-item">
          <h3>
            <FaTicketAlt /> Total Online Tickets Sold
          </h3>
          <p>{stats.totalTicketsSold}</p>
        </div>
        <div className="stat-item">
          <h3>
            <FaDollarSign /> Total Revenue
          </h3>
          <p>{stats.totalRevenue}</p>
        </div>
        <div className="stat-item">
          <h3>
            <FaUser /> Average Online Ticket Sales
          </h3>
          <p>{stats.averageAttendance}</p>
        </div>
        <div className="stat-item">
          <h3>
            <FaChartBar /> Highest Attendance By Online Sales
          </h3>
          <p>
            {stats.highestAttendanceEvent || "N/A"}: {stats.highestAttendance}
          </p>
        </div>
        <div className="stat-item">
          <h3>
            <FaChartBar /> Lowest Attendance By Online Sales
          </h3>
          <p>
            {stats.lowestAttendanceEvent || "N/A"}: {stats.lowestAttendance}
          </p>
        </div>
        <div className="stat-item">
          <h3>
            <FaStar /> Top Grossing Event By Online Sales
          </h3>
          <p>
            {stats.topGrossingEvent || "N/A"}: {stats.topGrossingRevenue}
          </p>
        </div>
      </div>
      <div className="attendance-chart">
        <h3>Event Online Ticket Sales</h3>
        {eventAttendanceData.labels.length > 0 ? (
          <Bar data={chartData} options={chartOptions} />
        ) : (
          <p>No data available for the chart.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardStats;
