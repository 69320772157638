import axios from "axios";
import { formatDate } from "../Utils/formatter";

const BASE_URL = "https://www.eventbriteapi.com/v3";
const AUTH_HEADER = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_EVENTBRITE_API_KEY}`,
  },
};

export interface EventData {
  id: string;
  title: string;
  date: string;
  formattedDate: string;
  ticketLink: string;
  displayImage: string;
}

export interface SalesData {
  grossRevenue: string;
  netRevenue: string;
  ticketsSold: number;
}

export interface Attendee {
  id: string;
  name: string;
  email: string;
  ticketClass: string;
  status: string;
  costs: {
    gross: number; // Gross ticket price in cents
    basePrice: number; // Base ticket price in cents
    eventbriteFee: number; // Eventbrite fee in cents
    paymentFee: number; // Payment fee in cents
    tax: number; // Tax in cents
  };
  eventId: string;
}

/**
 * Fetch events from the Eventbrite API.
 * @returns {Promise<{ events: EventData[], nextEvent: EventData | null }>}
 */
export const fetchEvents = async (): Promise<{
  events: EventData[];
  nextEvent: EventData | null;
}> => {
  try {
    const response = await axios.get(
      `https://www.eventbriteapi.com/v3/organizations/${process.env.REACT_APP_EVENTBRITE_ID}/events/?status=live`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_EVENTBRITE_API_KEY}`,
        },
      }
    );

    const eventsList = response.data.events.map((event: any) => ({
      id: event.id,
      title: event.name.text,
      date: event.start.local,
      formattedDate: formatDate(event.start.local),
      ticketLink: event.url,
      displayImage: event.logo ? event.logo.url : "",
    }));

    const upcomingEvents = eventsList.filter(
      (event: { date: string | number | Date }) =>
        new Date(event.date) > new Date()
    );

    const nextEvent =
      upcomingEvents.sort(
        (
          a: { date: string | number | Date },
          b: { date: string | number | Date }
        ) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )[0] || null;

    return { events: eventsList, nextEvent };
  } catch (error) {
    console.error("Error fetching events from Eventbrite: ", error);
    throw error;
  }
};

/**
 * Fetch events from the Eventbrite API.
 * @returns {Promise<{ events: EventData[], nextEvent: EventData | null }>}
 */
export const fetchAllEvents = async (): Promise<{
  events: EventData[];
  nextEvent: EventData | null;
}> => {
  try {
    const response = await axios.get(
      `https://www.eventbriteapi.com/v3/organizations/${process.env.REACT_APP_EVENTBRITE_ID}/events/?status=all`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_EVENTBRITE_API_KEY}`,
        },
      }
    );

    const eventsList = response.data.events.map((event: any) => ({
      id: event.id,
      title: event.name.text,
      date: event.start.local,
      formattedDate: formatDate(event.start.local),
      ticketLink: event.url,
      displayImage: event.logo ? event.logo.url : "",
    }));

    const upcomingEvents = eventsList.filter(
      (event: { date: string | number | Date }) =>
        new Date(event.date) > new Date()
    );

    const nextEvent =
      upcomingEvents.sort(
        (
          a: { date: string | number | Date },
          b: { date: string | number | Date }
        ) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )[0] || null;

    return { events: eventsList, nextEvent };
  } catch (error) {
    console.error("Error fetching events from Eventbrite: ", error);
    throw error;
  }
};

/**
 * Fetch attendees for a specific event.
 * @param eventId - The ID of the event.
 * @returns Array of Attendees
 */
export const fetchAttendees = async (eventId: string): Promise<Attendee[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/events/${eventId}/attendees/`,
      AUTH_HEADER
    );
    return response.data.attendees.map((attendee: any) => ({
      id: attendee.id,
      name: attendee.profile.name || `${attendee.profile.first_name} ${attendee.profile.last_name}`,
      email: attendee.profile.email,
      ticketClass: attendee.ticket_class_name,
      status: attendee.status,
      costs: {
        gross: attendee.costs.gross.value || 0,
        basePrice: attendee.costs.base_price.value || 0,
        eventbriteFee: attendee.costs.eventbrite_fee.value || 0,
        paymentFee: attendee.costs.payment_fee.value || 0,
        tax: attendee.costs.tax.value || 0,
      },
      eventId: attendee.event_id,
    }));
  } catch (error) {
    console.error(`Error fetching attendees for event ${eventId}:`, error);
    throw error;
  }
};

