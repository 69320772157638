export const formatDate = (isoString) => {
  const date = new Date(isoString);
  const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const formatTimeTo12Hour = (time24) => {
  const [hours, minutes] = time24.split(":").map(Number);

  // Calculate the 12-hour format
  const period = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;

  return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
}