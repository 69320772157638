import React from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import './EventModal.scss';

interface EventViewBoxProps {
  modalTitle?: string,
  event: {
    displayImage: string;
    title: string;
    formattedDate: string;
    ticketLink: string;
  } | null;
  onClose: () => void;
}

const EventViewBox: React.FC<EventViewBoxProps> = ({ modalTitle = "", event, onClose }) => {
  if (!event) return null;

  return (
    <Modal title={modalTitle} closeModal={onClose}>
      <div className="event-content">
        <img src={event.displayImage} alt={event.title} className="event-viewbox-image" />
        <div className="event-details">
          <h2>{event.title}</h2>
          <p><strong>Date:</strong> {event.formattedDate}</p>
        </div>
        <Button href={event.ticketLink} theme="primary">
          Buy Tickets
        </Button>
      </div>
    </Modal>
  );
};

export default EventViewBox;
