import { NavLink } from "react-router-dom";
import "./DashboardToolbar.scss";

const DashboardToolbar: React.FC = () => {
  return (
    <nav className="dashboard-toolbar" aria-label="Dashboard Navigation">
      <div className="toolbar-links">
        <NavLink
          to="home"
          className={({ isActive }) => (isActive ? "active-tab" : "tab")}
        >
          Dashboard
        </NavLink>
        <NavLink
          to="images"
          className={({ isActive }) => (isActive ? "active-tab" : "tab")}
        >
          Manage Images
        </NavLink>
        <NavLink
          to="videos"
          className={({ isActive }) => (isActive ? "active-tab" : "tab")}
        >
          Manage Videos
        </NavLink>
        <NavLink
          to="bookings"
          className={({ isActive }) => (isActive ? "active-tab" : "tab")}
        >
          Bookings
        </NavLink>
      </div>
    </nav>
  );
};

export default DashboardToolbar;
