import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Alert, Accordion } from "../../Components/components";
import { parseISO, differenceInCalendarDays, isBefore, formatISO, format } from "date-fns";
import { EventViewBox, Spinner, RequestBookingModal } from "../../Components/components";
import { fetchBookingDates, handleBookingRequest } from "../../APIs/BookingsAPI";
import { fetchAllEvents } from "../../APIs/EventsAPI";
import "./Calendar.scss";

interface Event {
  id: string;
  title: string;
  date: string;
  source: "firebase" | "eventbrite";
  displayImage?: string;
  formattedDate?: string;
  ticketLink?: string;
}

const CalendarPage: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [requestedDates, setRequestedDates] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [alert, setAlert] = useState<{ type: "success" | "error" | "warning" | "info"; message: string } | null>(null);

  const fetchFullEvents = async () => {
    try {
      setLoading(true);
      setError(null);

      const bookingDates = await fetchBookingDates();

      const { events: eventbriteEvents } = await fetchAllEvents();
      const eventbriteFormattedEvents = eventbriteEvents.map((event) => ({
        id: event.id,
        title: event.title,
        date: event.date,
        source: "eventbrite" as const,
        displayImage: event.displayImage,
        formattedDate: event.formattedDate,
        ticketLink: event.ticketLink,
      }));

      setEvents(eventbriteFormattedEvents);
      setRequestedDates(bookingDates);
    } catch (error) {
      console.error("Error fetching events or dates: ", error);
      setError("Failed to load events. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFullEvents();
  }, []);

  const handleDateClick = (info: DateClickArg) => {
    // Assume info.dateStr is in "YYYY-MM-DD"
    const selectedDateStr = info.dateStr;
    const selectedDate = parseISO(selectedDateStr);
    const today = new Date();
    const march2025 = parseISO("2025-03-01");

    // Check if the selected date is before March 2025
    if (isBefore(selectedDate, march2025)) {
      setAlert({
        type: "error",
        message: "We currently are not accepting events until March 2025.",
      });
      return;
    }

    // Check if today or in the past
    if (differenceInCalendarDays(selectedDate, today) <= 0) {
      setAlert({
        type: "error",
        message: "You cannot request a booking for today or past dates.",
      });
      return;
    }

    // Check if at least 14 days in advance
    if (differenceInCalendarDays(selectedDate, today) < 14) {
      setAlert({
        type: "warning",
        message: "Bookings must be made at least 14 days in advance.",
      });
      return;
    }

    // Check if this date already has a booking or request.
    // Make sure that the dates in requestedDates are also in "YYYY-MM-DD" format.
    if (requestedDates.includes(selectedDateStr)) {
      setAlert({
        type: "info",
        message: "This date has already been requested.",
      });
      return;
    }

    if (events.some(event => format(new Date(event.date), "yyyy-MM-dd") === selectedDateStr)) {
      setAlert({
        type: "info",
        message: "An event has already been confirmed for this date.",
      });
      return;
    }


    setSelectedDate(selectedDateStr);
    setIsModalOpen(true);
  };

  const handleEventClick = (info: { event: { id: string } }) => {
    const clickedEvent = events.find((event) => event.id === info.event.id);
    if (clickedEvent?.source === "eventbrite") {
      setSelectedEvent(clickedEvent);
    }
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const submitBookingRequest = async (
    name: string,
    contactEmail: string,
    description: string,
    time: string,
    additionalServices: { soundTech: boolean; doorPerson: boolean },
    cleanedLinks: { youtube: string; instagram: string; facebook: string; spotify: string }
  ) => {
    setLoading(true);
    try {
      await handleBookingRequest(
        name,
        contactEmail,
        description,
        time,
        selectedDate!,
        additionalServices,
        cleanedLinks
      );

      setAlert({ type: "success", message: "Booking request submitted successfully!" });

      await fetchFullEvents();
    } catch (error) {
      console.error("Error submitting booking request:", error);
      setAlert({ type: "error", message: "Failed to submit booking request." });
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="calendar-page">
      <Accordion className="info-accordion" title="Viewing Events & Buying Tickets" initiallyExpanded={false} theme="primary">
        <p>Looking for upcoming events? Click on an event in the calendar to see details, including ticket links.</p>
        <p>Many of our events are hosted on <a href='https://www.eventbrite.ca/o/rare-candyz-77027845793' target='_blank' rel='noopener noreferrer'>Eventbrite</a>. If a ticket is required, you’ll find a link to purchase tickets directly.</p>
        <p>Follow us on <a href="https://www.instagram.com/rarecandyz_dogpit/" target="_blank" rel="noopener noreferrer">Instagram</a> for event updates.</p>
      </Accordion>

      <Accordion className="info-accordion" title="Booking with the Calendar" initiallyExpanded={false} theme="secondary">
        <p>Select a date to request a booking. Bookings must be made at least <strong>14 days in advance</strong> and are only available starting <strong>March 2025</strong>.</p>
        <p>Dates that are already booked or requested will be marked as unavailable.</p> 
        <p>Click on an event to see more details.</p>
      </Accordion>

      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          duration={5000}
        />
      )}
      {loading ? (
        <div className="calendar-loading-container">
          <p>Loading events...</p>
          <Spinner />
        </div>
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events.map((event) => ({
            id: event.id,
            title: event.title,
            start: event.date,
            className: event.source === "eventbrite" ? "eventbrite-event" : "",
          }))}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          selectable={true}
          dayCellClassNames={(dateInfo) => {
            const normalizedDateStr = formatISO(new Date(dateInfo.date)).split("T")[0];
            return requestedDates.includes(normalizedDateStr) ? "blocked-date" : "";
          }}
        />
      )}

      {selectedEvent && (
        <EventViewBox
          event={{
            displayImage: selectedEvent.displayImage || "",
            title: selectedEvent.title,
            formattedDate: selectedEvent.formattedDate || "",
            ticketLink: selectedEvent.ticketLink || "#",
          }}
          onClose={handleCloseModal}
        />
      )}

      <RequestBookingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={submitBookingRequest}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default CalendarPage;
