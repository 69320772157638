import { PDFDocument, StandardFonts } from "pdf-lib";
import { formatDate, formatTimeTo12Hour } from "./formatter";

export interface PdfData {
  date: string;
  client: {
    name: string;
    email: string;
  };
  eventDetails: {
    name: string;
    dates: string;
    startTime: string;
    endTime: string;
    loadInTime: string;
  };
  paymentTerms: {
    rentalFee: string;
    deposit: string;
    depositDueDate: string;
    balanceDue: string;
    dueDate: string;
  };
  cancellationPolicy: {
    clientPolicy: string;
    ownerPolicy: string;
  };
  additionalServices: {
    soundTech: boolean;
    doorPerson: boolean;
  };
}

export const createVenueBookingContractPDF = async (
  contractData: PdfData
): Promise<string> => {
  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const timesRomanBoldFont = await pdfDoc.embedFont(
    StandardFonts.TimesRomanBold
  );
  const timesRomanItalicFont = await pdfDoc.embedFont(
    StandardFonts.TimesRomanItalic
  );

  let page = pdfDoc.addPage();
  const { width, height } = page.getSize();
  let yPosition = height - 50;

  const fontSize = 12;
  const titleFontSize = 14;
  const sectionSpacing = 30;
  const lineSpacing = 18;

  // Utility to handle page overflow
  const checkPageOverflow = () => {
    if (yPosition < 50) {
      page = pdfDoc.addPage();
      yPosition = height - 50;
    }
  };

  // Utility to draw text
  const drawText = (text: string, font = timesRomanFont, size = fontSize) => {
    checkPageOverflow();
    page.drawText(text, { x: 50, y: yPosition, size, font });
    yPosition -= lineSpacing;
  };

  // Utility to draw section titles
  const drawSectionTitle = (title: string) => {
    yPosition -= 10; // Add extra spacing before the title
    drawText(title, timesRomanBoldFont, titleFontSize);
    yPosition -= 10; // Add spacing after the title
  };

  // Utility to draw a horizontal separator
  const drawSeparator = () => {
    page.drawLine({
      start: { x: 50, y: yPosition },
      end: { x: width - 50, y: yPosition },
      thickness: 1,
    });
    yPosition -= 15;
  };

  // Utility to wrap and draw long text
  const drawWrappedText = (
    text: string,
    font = timesRomanFont,
    maxWidth = width - 100,
    size = fontSize
  ) => {
    const words = text.split(" ");
    let line = "";

    words.forEach((word) => {
      const testLine = line ? `${line} ${word}` : word;
      const lineWidth = font.widthOfTextAtSize(testLine, size);

      if (lineWidth > maxWidth) {
        drawText(line, font, size);
        line = word;
      } else {
        line = testLine;
      }
    });

    if (line) drawText(line, font, size);
  };

  const addFooter = (page: any, pageIndex: number, totalPages: number) => {
    const footerText = `Venue Booking Agreement | Page ${
      pageIndex + 1
    } of ${totalPages} | Generated on: ${new Date().toLocaleDateString(
      "en-US"
    )}`;
    const footerYPosition = 30;

    page.drawText(footerText, {
      x: 50,
      y: footerYPosition,
      size: fontSize - 2,
      font: timesRomanFont,
    });
  };

  // Section renderers
  const renderHeader = () => {
    drawText("Venue Booking Agreement", timesRomanBoldFont, 16);
    yPosition -= sectionSpacing;
  };

  const renderVenueOwner = () => {
    drawSectionTitle('Venue Owner/Operator ("Owner")');
    drawText("Name: Tristan Humann");
    drawText("Email: booking@dogpit.ca");
    drawText("Address: 310 Vancouver Street, London Ontario");
    drawSeparator();
  };

  const renderClientDetails = () => {
    drawSectionTitle('Client ("Client")');
    drawText(`Name: ${contractData.client.name}`);
    drawText(`Email: ${contractData.client.email}`);
    drawText(`Address: _________________________________________`);
    drawText("");
    drawText(
      "Hereinafter referred to collectively as the 'Parties' or individually as the 'Owner' and the 'Client.'",
      timesRomanItalicFont
    );
    drawSeparator();
  };

  const renderEventDetails = () => {
    drawSectionTitle("1. Event Details");
    drawText(`- Event Date: ${formatDate(contractData.eventDetails.dates)}`);
    drawText(
      `- Start Time: ${formatTimeTo12Hour(contractData.eventDetails.startTime)}`
    );
    drawText(
      `- End Time: ${formatTimeTo12Hour(contractData.eventDetails.endTime)}`
    );
    drawText("");
    drawText(
      "Changes to the Event Date, Start Time, or End Time must be mutually agreed upon by the Parties in writing.",
      timesRomanItalicFont
    );
    drawSeparator();
  };

  const renderAdditionalServices = () => {
    drawSectionTitle("2. Additional Services");
    const { soundTech, doorPerson } = contractData.additionalServices;
    if (soundTech) {
      drawText("- Sound Tech: $50.00");
      drawText(
        "Sound Tech Services: Includes setup, management, and teardown of audio equipment."
      );
    }
    if (doorPerson) {
      drawText("- Door Person: $50.00");
      drawWrappedText(
        "The Door Person service includes managing entry to the venue, checking tickets or guest lists, assisting with crowd control, and ensuring a secure and organized entry process for attendees."
      );
    }
    drawText("");
    drawWrappedText(
      "Additional services requested after the signing of this Contract may incur additional fees and must be agreed upon in writing.",
      timesRomanItalicFont
    );
    drawSeparator();
  };

  const renderPaymentTerms = () => {
    drawSectionTitle("3. Payment Terms");

    // Detailed Payment Breakdown
    drawText(`- Rental Fee: $${contractData.paymentTerms.rentalFee} CAD`, timesRomanBoldFont);
    drawWrappedText(
      `The Rental Fee covers the exclusive use of the venue for the agreed event duration, including basic amenities and any additonal services requested.`, timesRomanItalicFont
    );
  
    // Deposit Information
    drawText(
      `- Deposit: $${contractData.paymentTerms.deposit} CAD`,
      timesRomanBoldFont
    );
    drawWrappedText(
      `The deposit is required to secure the venue booking and must be paid by ${formatDate(
        contractData.paymentTerms.depositDueDate
      )}. Failure to pay the deposit by this date may result in cancellation of the booking.`, timesRomanItalicFont
    );
  
    // Balance Due
    drawText(
      `- Balance Due: $${contractData.paymentTerms.balanceDue} CAD`,
      timesRomanBoldFont
    );
    drawWrappedText(
      `The remaining balance must be paid in full by ${formatDate(
        contractData.paymentTerms.dueDate
      )}. The balance is calculated as the total Rental Fee minus the Deposit.`
    );
  
    drawSeparator();
  };

  const renderCancellationPolicy = () => {
    drawText("");
    drawText("");
    drawText("");
    drawText("");
    drawSectionTitle("4. Cancellation Policy");
    drawWrappedText(
      `- Cancellation by Client: ${contractData.cancellationPolicy.clientPolicy}`
    );
    drawText("");
    drawWrappedText(
      `- Cancellation by Owner: ${contractData.cancellationPolicy.ownerPolicy}`
    );
    drawSeparator();
  };

  const renderLiabilityClause = () => {
    drawSectionTitle("5. Liability and Indemnity Clause");
    drawWrappedText(
      "The Owner is not liable for any damage, loss, or injury incurred by the Client or attendees during the event, except in cases of gross negligence or willful misconduct. The Client agrees to indemnify and hold the Owner harmless from any claims arising from the Client’s use of the venue."
    );
    drawSeparator();
  };

  const renderMajeureClause = () => {
    drawSectionTitle("6. Force Majeure Clause");
    drawWrappedText(
      `Neither Party shall be held responsible for delays or failure to perform due to acts of God, government restrictions, pandemics, or other unforeseeable events beyond their control.`
    );
    drawSeparator();
  };

  const renderTerminationClause = () => {
    drawSectionTitle("7. Termination Clause");
    drawWrappedText(
      `This Contract may be terminated by mutual written agreement between the Parties. In such cases, the deposit will be refunded unless the termination occurs within 14 calendar days of the event.`
    );
    drawSeparator();
  };

  const renderSignatures = () => {
    drawSectionTitle("8. Signatures");
    drawText(
      "Print Name: ___________________________  Owner Signature: ___________________________"
    );
    drawText("");
    drawText("Date: ____________________________");
    drawText("");
    drawText("");
    drawText(
      "Print Name: ___________________________  Client Signature: ___________________________"
    );
    drawText("");
    drawText("Date: ____________________________");
  };

  // Render sections
  renderHeader();
  renderVenueOwner();
  renderClientDetails();
  renderEventDetails();
  renderAdditionalServices();
  renderPaymentTerms();
  renderCancellationPolicy();
  renderLiabilityClause();
  renderMajeureClause();
  renderTerminationClause();
  renderSignatures();

  const pages = pdfDoc.getPages();
  pages.forEach((pg, idx) => addFooter(pg, idx, pages.length));

  // Save PDF
  const pdfBytes = await pdfDoc.save();
  return btoa(String.fromCharCode(...new Uint8Array(pdfBytes)));
};
