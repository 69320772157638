import React from "react";
import "./Spinner.scss";

interface SpinnerProps {
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  size?: number; // Size in pixels
  className?: string; // Additional className for styling
}

const Spinner: React.FC<SpinnerProps> = ({
  theme = "primary",
  size = 40, // Default size is 40px
  className = "",
}) => {
  return (
    <div
      className={`spinner ${theme} ${className}`}
      style={{
        width: size,
        height: size,
        borderWidth: size / 8, // Adjust the border width relative to size
      }}
    ></div>
  );
};

export default Spinner;
