import React from "react";
import "./Footer.scss";
import { socialLinks } from "../../Utils/SocialLinks";
import SmIconButton from "../UI-Elements/SmallIconButton/SmallIconButton";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        {socialLinks.map((link, index) => (
          <SmIconButton
            icon={link.icon}
            key={index}
            href={link.href}
            className="footer-link"
          />
        ))}
      </div>
      <div className="footer-copyright">
        &copy; Rare Candyz 2024. All rights reserved. <br />
        <p className="credit-footer">
          Designed by{" "}
          <a
            href="https://www.davinchiupka.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Davin Chiupka
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
